"use client"
import React, { useState, useRef, useEffect } from "react"
import BannerSlider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Navbar from "../Navbar";
import { Button } from "flowbite-react"
import banner1Img from "../Home/Images/home-banner-1.jpg";
import banner2Img from "../Home/Images/home-banner-2.jpg";
import banner3Img from "../Home/Images/home-banner-3.jpg";
import { useNavigate } from 'react-router-dom';

function HomeBanner() {
  var setting = {
    speed: 500,
    slidesToShow: 1,
    initialSlide: 1,
    slidesToScroll: 1,
    fade: false,
    autoplay: true,
    arrow: false,
    dots: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const navigate = useNavigate();
  const handleContactusClick = () => {
    navigate("/contactus");
  };
  return (
    <section className="text-center relative w-full mx-auto overflow-hidden md:h-screen">


      <Navbar />
      <div className="text-center">

        <BannerSlider {...setting} className="z-0">
         
          <div className="relative overflow-hidden">
           
           <img
                src={banner2Img}
                className="w-full lg:h-auto h-[500px] z-0"
              width={800}
              height={120}
              alt=""
              priority
              />
            <p className="text-white lg:text-6xl text-3xl font-bold z-50 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full">Unlocking Abundance</p>
          </div>

          <div className="relative overflow-hidden">
          <img
                src={banner3Img}
                className="w-full lg:h-auto h-[500px] z-0"
              width={800}
              height={120}
              alt=""
              priority
              />
            
            <p className="text-white lg:text-6xl text-3xl font-bold z-50 absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full">Client-Centric Problem Solving</p>
            <p className="text-white lg:text-2xl text-1xl z-50 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full">We are a counsel-led Indian legal practice  representing Indian and</p>
            <p className="text-white lg:text-2xl text-1xl z-50 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full my-10">international interest in diverse areas of expertise.</p>
            <Button  onClick={handleContactusClick}
             className="text-red-600 bg-slate-100 p-1 z-50 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 my-24 rounded-none">Contact Us
             </Button>


          </div>
          <div className="relative overflow-hidden">
          <img
                src={banner1Img}
                className="w-full lg:h-auto h-[500px] z-0"
              width={800}
              height={120}
              alt=""
              priority
              />
            
            <div className="text-white lg:text-6xl text-3xl font-bold z-50 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full"><p>Rooted in India,</p><p className="mt-4">Global in Practice.</p></div>
          </div>

        </BannerSlider>

      </div>
    </section>
  )
}

export default HomeBanner
