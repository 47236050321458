import imageURLDeatils1 from "../Images/image-1@2x.png";
import imageURLDeatils2 from "../Images/image-2@2x.png";
export const numbers = [

  {
    id: 1,
    rupees: "100+",
    title: "Years of Our Legacy",
  },

  {
    id: 2,
    rupees: "1500+",
    title: "Clients Served",
  },

  {
    id: 3,
    rupees: "6 Bn $ +",
    title: "of Disputes Resolved",
  },

  

]


export const testimonials = [
  {
    id: 1,
    imageUrl:imageURLDeatils1,
    name: "Anjana Chakravorty",
    post: " Manager  IVista India",
    desc: "“My interaction with Aarna Law Offices India in a couple of important professional engagements has left me with the abiding impression of their utmost excellence in professionalism. The efficiency, courtesy, and the finesse evident in their dealings with the clients is quite commendable. In-depth research into legal issues gives them the cutting edge over their peers and demonstrates the hallmark of distinction that they have attained as a legal firm.”",
  },
  {
    id: 2,
    imageUrl: imageURLDeatils2,
    name: "Sanjana Yogesh",
    post: "Sr Manager  IVista India",
    desc: "“My interaction with Aarna Law Offices India in a couple of important professional engagements has left me with the abiding impression of their utmost excellence in professionalism. The efficiency, courtesy, and the finesse evident in their dealings with the clients is quite commendable. In-depth research into legal issues gives them the cutting edge over their peers and demonstrates the hallmark of distinction that they have attained as a legal firm.”",
  },
];


